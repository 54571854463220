import { Component, OnInit } from '@angular/core';
import { isWithinInterval, getDate, getDay, getWeek, formatDistance, formatDistanceToNow, addDays, setHours, setMinutes, setSeconds, setMilliseconds, differenceInHours, differenceInSeconds } from 'date-fns'
import { da } from 'date-fns/locale'
import { interval } from 'rxjs';
import { startWith } from 'rxjs/operators';

export enum DayType {
  pizza = 1,
  vegan = 2,
  cake = 3,
  breakfast = 5,
  strip = 50,
  christmasParty = 51,
  christmasHolydayFriday = 52,
  christmasHolyday = 53,
  other = 99

}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  
  /* TODO:
    Pipe for twoDigits
    Component for count down clock
    Move calculations to service
    Move style to css file
  */

  
  public show: boolean = false;
  public currentDayType: DayType = DayType.other;
  public nextPizzaFriday: string = '';
  public dayTypeEnum = DayType;
  public inSeconds: string;
  public inDays: string;
  public inHours: string;
  public inMinutes: string;
  
  
  constructor() { 

  }

  ngOnInit() {  

    const secondsCounter = interval(1000);
    
    secondsCounter
      .pipe(startWith(0)) // first run 0 seconds: init
      .subscribe((x) => 
      {
        this.update();
      });

  }

  private update = () => {
    var date = this.now();
    
    this.nextPizzaFriday = this.getNextPizzaFridayText();
    
    let isPizzaFriday = this.isPizzaFriday(date);
    let isVegan = this.isVegan(date);
    let isCakeDay = this.isCakeDay(date);
    let isBreakfast = this.isBreakfast(date);
    let isStrip = this.isStrip(date);
    let isChristmasParty = this.isChristmasParty(date);
    let isChristmasHolydayFriday = this.isChristmasHolydayFriday(date);
    let isChristmasHolyday = this.isChristmasHolyday(date);

    if (isChristmasHolydayFriday)
    {
      this.currentDayType = DayType.christmasHolydayFriday;
    }
    else if (isChristmasHolyday)
    {
      this.currentDayType = DayType.christmasHolyday;
    }
    else if (isChristmasParty)
    {
      this.currentDayType = DayType.christmasParty;
    }
    else if(isPizzaFriday) {
      this.currentDayType = DayType.pizza;
    }
    else if(isStrip) {
      this.currentDayType = DayType.strip;
    }
    else if(isVegan) {
      this.currentDayType = DayType.vegan;
    }
    else if(isCakeDay) {
      this.currentDayType = DayType.cake;
    }
    else if(isBreakfast) {
      this.currentDayType = DayType.breakfast;
    }
    else {
      this.currentDayType = DayType.other;
    }
    

    let nextPizzaFriday : Date = this.getNextPizzaFriday();
    let totalDifferenceInSeconds = differenceInSeconds(nextPizzaFriday, date);

    if(totalDifferenceInSeconds < 0) {
      totalDifferenceInSeconds = 0; // TODO: stop for 30 min and stop animation
    }

    let days    = Math.floor(totalDifferenceInSeconds / (3600 * 24));
    let hours   = Math.floor((totalDifferenceInSeconds - (days * (3600 * 24)))/3600);
    let minutes = Math.floor((totalDifferenceInSeconds - (days * (3600 * 24)) - (hours * 3600)) / 60);
    let seconds = Math.floor(totalDifferenceInSeconds - (days * (3600 * 24)) - (hours * 3600) - (minutes * 60));

    this.inDays = this.twoDigit(days);
    this.inHours = this.twoDigit(hours);
    this.inMinutes = this.twoDigit(minutes);
    this.inSeconds = this.twoDigit(seconds);
  }

  twoDigit = (number: number) : string => {
    return ('0' + number).slice(-2);
  }

  private getNextPizzaFridayText = (): string => {
    let nextPizzaFriday : Date = this.getNextPizzaFriday();
    return  'Pizza-fredag ' + 
      formatDistance(nextPizzaFriday, this.now(), { addSuffix: true, includeSeconds: true, locale: da });
  }

  private getNextPizzaFriday = (): Date => {
    let nextFriday : Date = this.getNextFriday(this.now());
    let nextPizzaFriday : Date = this.isPizzaFriday(nextFriday) ? nextFriday : addDays(nextFriday, 7);
    
    // set time to 12:00
    nextPizzaFriday = setMinutes(nextPizzaFriday, 0);
    nextPizzaFriday = setSeconds(nextPizzaFriday, 0);
    nextPizzaFriday = setMilliseconds(nextPizzaFriday, 0);
    nextPizzaFriday = setHours(nextPizzaFriday, 12);

    return nextPizzaFriday;
  }

  private getNextFriday = (start: Date): Date => {
    let daysToAdd = (5 - getDay(start) + 7) % 7; // 5:friday
    return addDays(start, daysToAdd);
  }
  
  private isPizzaFriday = (date: Date): boolean => {
    // fridays with even weeknumber
    let evenWeekNumber: boolean = getWeek(date, { locale: da }) % 2 === 0;
    let friday: boolean = getDay(date) === 5;
    
    return evenWeekNumber && friday;
  }

  private isVegan(date: Date): boolean {
    // first tuesday of every month is vegan day, yikes !
    let tuesday: boolean = getDay(date) === 2;
    let firstWeekOfMonth: boolean = getDate(date) <= 7;
    return tuesday && firstWeekOfMonth;
  }

  private isCakeDay(date: Date): boolean {
    // we eat cake every wednesday
    let wednesday: boolean = getDay(date) === 3;
    return wednesday
  }

  isBreakfast(date: Date) {
    // fridays with uneven weeknumber
    let evenWeekNumber: boolean = getWeek(date, { locale: da }) % 2 === 1;
    let friday: boolean = getDay(date) === 5;
    
    return evenWeekNumber && friday;
  }

  isStrip(date: Date) : Boolean {
    return isWithinInterval(date, { start: new Date(2019, 9, 25), end: new Date(2019, 9, 29, 23, 59) });
  }

  isChristmasParty(date: Date) : Boolean {
    return isWithinInterval(date, { start: new Date(2019, 10, 29), end: new Date(2019, 10, 29, 23, 59) });
  }

  isChristmasHolyday(date: Date) : Boolean {
    return isWithinInterval(date, { start: new Date(2019, 11, 20, 15, 0), end: new Date(2020, 0, 1, 23, 59) });
  }

  isChristmasHolydayFriday(date: Date) : Boolean {
    return isWithinInterval(date, { start: new Date(2019, 11, 19), end: new Date(2019, 11, 20, 14, 59) });
  }
  
  private now = (): Date => {
    //return new Date(2019, 9, 18, 11, 59, 49);
    return new Date();
  }

}
